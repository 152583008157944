Px.Editor.MainStore = class MainStore extends Px.BaseStore {

  constructor(params) {
    super(params);
    this.project_id = params.project_id || null;
    this.theme_id = params.theme_id || null;
    this.resource_type = params.resource_type || 'book';  // one of: book, theme_templates, theme_layouts

    this.images = Px.Editor.ImageStore.make(
      this.resource_type,
      this.project_id,
      {onImageUploaded: this.onImageUploaded}
    );
    this.pdfs = Px.Editor.PdfStore.make({onPdfUploaded: this.onPdfUploaded});
    this.options = Px.Editor.OptionStore.make(this.project_id, this.images);
    this.theme = Px.Editor.ThemeStore.make(this.theme_id, this.images, this.pdfs);
    this.layouts = Px.Editor.LayoutStore.make(
      this.resource_type === 'book' ? this.project_id : null,
      this.theme_id,
      this.images,
      this.pdfs
    );

    if (this.resource_type === 'book') {
      this.project = Px.Editor.BookProjectStore.make(this.project_id, this);
    } else if (this.resource_type === 'theme_templates') {
      this.project = Px.Editor.ThemeTemplatesProjectStore.make(this.theme, this.images, this.pdfs);
    } else if (this.resource_type === 'theme_layouts') {
      this.project = Px.Editor.ThemeLayoutsProjectStore.make(this.theme, this.images, this.pdfs);
    } else {
      throw new Error(`Unsupported resource_type: '${this.resource_type}'`);
    }

    this.backgrounds = Px.Editor.BackgroundStore.make(this.theme_id, this.images, this.project, this.layouts);
    this.texts = Px.Editor.TextStore.make(this.project_id);
    this.galleries = Px.Editor.GalleryStore.make(this);
    this.masks = Px.Editor.MaskGalleryStore.make(this.theme_id, this.images);
    this.font_palettes = Px.Editor.FontPaletteStore.make(this.theme_id);
    this.color_palettes = Px.Editor.ColorPaletteStore.make();
    this.undo_redo = Px.Editor.UndoRedoStore.make(this);
    this.notifications = Px.Editor.NotificationStore.make();
    this.clipboard = Px.Editor.ClipboardStore.make(this.images, this.pdfs);
    this.ui = Px.Editor.UIStore.make(this);
    this.mobile = Px.Editor.MobileStore.make(this);
    this.cut_prints = Px.Editor.CutPrintStore.make();

    this.external_integration = Px.Editor.Integrations[params.integration] || {};

    mobx.reaction(() => this._price_variables, variables => this.fetchPrice(variables), {
      fireImmediately: true,
      name: 'Px.Editor.MainStore::FetchProductPriceReaction',
      equals: mobx.comparer.structural,
      delay: 250
    });
  }

  static get properties() {
    return {
      price: {std: null},
      _selected_set: {std: null},
      _selected_page: {std: null},
      _selected_element: {std: null}
    };
  }

  static get computedProperties() {
    return {
      loading: function() {
        return this.project.loading;
      },
      cut_print_mode: function() {
        return Px.config.cut_print_mode && this.theme.cut_print && this.resource_type === 'book';
      },
      main_view: function() {
        if (this.cut_print_mode && !this.selected_set) {
          return 'cut-prints';
        } else {
          return this.ui.main_view;
        }
      },
      selected_set: function() {
        var selected = null;
        if (this._selected_set && this._selected_set.in_layout) {
          selected = this._selected_set;
        } else if (this.ui.editor_mode === 'mobile') {
          if (this.skip_mobile_project_mode && !this.cut_print_mode) {
            selected = this.project.editor_page_sets[0];
          }
        } else if (this.project.editor_page_sets.length > 0 && !this.cut_print_mode) {
          selected = this.project.editor_page_sets[0];
        }
        return selected;
      },
      selected_page: function() {
        var selected = null;
        if (this.selected_set && _.include(this.selected_set.pages, this._selected_page)) {
          selected = this._selected_page;
        } else if (this.selected_set) {
          selected = this.selected_set.pages[0];
        }
        return selected;
      },
      selected_element: function() {
        if (this._selected_element &&
            !this._selected_element.destroyed &&
            this._selected_element.page === this.selected_page) {
          return this._selected_element;
        }
        return null;
      },
      image_sources: function() {
        let sources = this.resource_type === 'book' ? this.galleries.image_sources : ['theme_resources'];
        if (Px.config.clipart_tab && !sources.includes('clipart')) {
          sources = sources.concat(['clipart']);
        }
        return sources;
      },
      crop_bleed: function() {
        if (this.ui.preview_mode) {
          return true;
        }
        const crop_bleed_qs = Px.urlQuery()['crop-bleed'];
        if (crop_bleed_qs) {
          return crop_bleed_qs === 't' || crop_bleed_qs === 'true';
        }
        return Px.config.crop_bleed;
      },
      color_picker_type_switch_enabled: function() {
        return Px.config.cmyk_color_picker || Px.config.advanced_edit_mode;
      },
      debug_gutter: function() {
        const debug_gutter_qs = Px.urlQuery()['debug-gutter'];
        return debug_gutter_qs === 't' || debug_gutter_qs === 'true';
      },
      share_code: function() {
        return Px.config.share_code;
      },
      is_single_page_product: function() {
        return Boolean(this.project.editor_page_sets.length === 1 && !this.theme.grow_set_definition);
      },
      has_mapped_preview: function() {
        return Px.config.use_mapped_preview && this.resource_type === 'book' && this.theme.mapped_previews.length > 0;
      },
      counted_page_count: function() {
        return this._countedPageCount(true);
      },
      quantity: function() {
        return parseInt(Px.urlQuery().quantity || 1, 10);
      },
      total_cut_print_quantity: function() {
        let count = 0;
        if (this.cut_print_mode) {
          this.project.cut_print_sets_with_definitions.forEach(set_with_definition => {
            set_with_definition.set.pages.forEach(page => {
              count += page.quantity;
            });
          });
        }
        return count;
      },
      skip_mobile_project_mode: function() {
        if (!this.is_single_page_product) {
          return false;
        }
        if (!(this.layouts.loaded && this.options.loaded)) {
          return false;
        }
        const page_set = this.project.editor_page_sets.length && this.project.editor_page_sets[0];
        if (!page_set) {
          return false;
        }
        const has_applicable_layouts = this.layouts.layouts.some(layout => {
          return page_set.pages.some(page => layout.applicableToPage(page, this.options));
        });
        return !has_applicable_layouts;
      },
      quicksave_prompt_delay: function() {
        const url_query = Px.urlQuery();
        if (this.resource_type !== 'book' ||
            this.ui.share_view ||
            this.project.local_images.length > 0 ||
            url_query.parent_orderline ||
            url_query.cart === 't') {
          return null;
        }
        const delay_in_seconds = parseFloat(Px.config.quicksave_prompt_delay) || 0;
        if (!delay_in_seconds) {
          return null;
        }
        return delay_in_seconds * 60 * 1000;
      },
      _price_variables: function() {
        if (this.resource_type !== 'book') {
          return null;
        }
        if (!(this.project.loaded && this.theme.loaded)) {
          return null;
        }
        // Most cut print pricing formulas don't handle the 0 prints case,
        // so don't show the price when there are no prints.
        if (this.cut_print_mode && this.total_cut_print_quantity === 0) {
          return null;
        }
        const counted_pages = this._countedPageCount(false);
        const uncounted_pages = this._uncountedPageCount(false);
        return {
          pages: counted_pages + uncounted_pages,
          uncounted_pages: uncounted_pages,
          quantity: this.quantity,
          cut_print_quantity: this.total_cut_print_quantity,
          variants: mobx.toJS(this.project.options),
          template_options: mobx.toJS(this.project.template_options),
          print_theme_id: this.theme_id
        };
      }
    };
  }

  get actions() {
    return {
      load: function() {
        // TODO: Not all of these actions actually return promises.
        // It would be cleaner to only add things that are actually promises
        // to the promises array.
        var promises = [
          this.theme.load(),
          this.project.load(),
          this.layouts.load(),
          this.backgrounds.load(),
          this.masks.load()
        ];
        if (this.resource_type === 'book') {
          promises.push(this.options.load());
          promises.push(this.galleries.project.load());
        }
        if (Px.config.clipart_tab) {
          promises.push(this.galleries.clipart.load());
        }
        if (Px.config.advanced_edit_mode) {
          this.color_palettes.loadAll();
          this.font_palettes.loadAll();
          this.galleries.theme_resources.load();
        } else if (Px.config.background_color_palette_id) {
          this.color_palettes.load(Px.config.background_color_palette_id);
        }
        return Promise.all(promises);
      },

      selectSet: function(set) {
        if (set === null || set.in_layout) {
          if (this._selected_set !== set) {
            this._selected_set = set;
            this._selected_page = null;
            this._selected_element = null;
          }
        }
      },

      selectSetById: function(id) {
        const set = this.project.getSetById(id);
        if (set) {
          this.selectSet(set);
        }
      },

      selectPreviousSet: function() {
        if (this.selected_set && this.selected_set.editor_position > 0) {
          this.selectSet(this.project.editor_page_sets[this.selected_set.editor_position - 1]);
        }
      },

      selectNextSet: function() {
        if (this.selected_set && this.selected_set.editor_position < this.project.editor_page_sets.length - 1) {
          this.selectSet(this.project.editor_page_sets[this.selected_set.editor_position + 1]);
        }
      },

      selectPage: function(page) {
        if (this.selected_set && _.include(this.selected_set.pages, page)) {
          this._selected_page = page;
        }
      },

      selectPageById: function(id) {
        const page = this.project.getPageById(id);
        if (page) {
          this.selectPage(page);
        }
      },

      selectElement: function(element) {
        const previous_selected_element = this.selected_element;
        if (element === null) {
          this._selected_element = null;
        } else if (this.selected_set) {
          var belongs_to_displayed_set = _.any(this.selected_set.pages, function(page) {
            return element.page === page;
          });
          if (belongs_to_displayed_set) {
            this._selected_element = element;
            this._selected_page = element.page;
          }
        }
        if (this.selected_element) {
          this.selected_element.update({is_selected: true});
        }
        if (previous_selected_element &&
            !((previous_selected_element === this.selected_element) ||
              (previous_selected_element.two_page_spread_clone &&
               previous_selected_element.two_page_spread_clone === this.selected_element))) {
          previous_selected_element.update({is_selected: false});
        }
      },

      addOrRemoveFromElementSelection: function(element) {
        let selection;
        if (this.selected_element && this.selected_element.type === 'selection' &&
            this.selected_element.page === element.page) {
          selection = this.selected_element;
        } else {
          selection = Px.Editor.ElementSelectionModel.make({page: element.page});
          if (this.selected_element) {
            selection.addElement(this.selected_element);
          }
          this.selectElement(selection);
        }
        if (selection.hasElement(element)) {
          selection.removeElement(element);
          if (selection.elements.length === 0) {
            selection.destroy();
            this.selectElement(null);
          }
        } else {
          selection.addElement(element);
        }
      },

      startResizingElement: function(element, pageX, pageY) {
        element.update({is_resizing: true});
        this.grabElement(element, pageX, pageY);
      },

      stopResizingElement: function(element) {
        element.update({is_resizing: false});
        this.releaseElement(element);
      },

      grabElement: function(element, pageX, pageY) {
        if (this.selected_element && this.selected_element.type === 'selection' &&
            this.selected_element.hasElement(element)) {
          element = this.selected_element;
        }
        this.selectElement(element);
        element.update({is_grabbed: true});
        this.ui.startDrag(element, pageX, pageY);
      },

      releaseElement: function(element) {
        element.update({is_grabbed: false});
        this.ui.stopDrag();
      },

      deleteElement: function(element) {
        this.undo_redo.withUndo(function() {
          element.destroy();
        }, {
          label: 'delete ' + element.type + ' element',
          set_id: element.page.set.id
        });
      },

      addImage: function(props, page) {
        props = props || {};
        page = page || this.selected_page;
        if (!page) {
          throw new Error('Cannot add image; no page selected');
        }
        const width = props.width || page.width/3;
        const height = props.height || page.height/3;
        const placeholder = props.id ? false : true;
        const defaults = {
          edit: true,
          id: null,
          x: (page.width - width) / 2,
          y: (page.height - height) / 2,
          width: width,
          height: height,
          placeholder: placeholder,
          image_store: this.images
        };
        return this._addElement('image', _.extend(defaults, props), page);
      },

      addText: function(props, page) {
        props = props || {};
        page = page || this.selected_page;
        if (!page) {
          throw new Error('Cannot add text; no page selected');
        }
        const height = page.height / 7;
        const pt = parseFloat(Px.config.default_font_size) || Math.round(Px.Util.mm2pt(height) / 1.5);
        const width = Math.max(Math.round(Px.Util.mm2pt(height) / 1.5), pt);
        const x = (page.width - width) / 2;
        const y = (page.height - height) / 2;
        const defaults = {
          text: Px.Editor.TextElementModel.default_text_content,
          edit: true,
          placeholder: true,
          x: x,
          y: y,
          width: width,
          height: height,
          pointsize: pt,
          fontpalette: Px.config.default_font_palette_id || null,
          font: Px.config.default_font_id,
          dir: Px.config.rtl ? 'rtl' : 'ltr',
          align: Px.config.rtl ? 'right' : 'left'
        };
        return this._addElement('text', _.extend(defaults, props), page);
      },

      addPdfElement: function(props, page) {
        props = props || {};
        page = page || this.selected_page;
        if (!page) {
          throw new Error('Cannot add PDF element; no page selected');
        }
        const width = props.width || page.width/3;
        const height = props.height || page.height/3;
        const placeholder = props.id ? false : true;
        const defaults = {
          edit: true,
          id: null,
          x: (page.width - width) / 2,
          y: (page.height - height) / 2,
          width: width,
          height: height,
          placeholder: placeholder,
          pdf_store: this.pdfs
        };
        return this._addElement('pdf', _.extend(defaults, props), page);
      },

      addBarcode: function(props, page) {
        props = props || {};
        page = page || this.selected_page;
        if (!page) {
          throw new Error('Cannot add barcode; no page selected');
        }
        const width = props.width || page.width/3;
        const height = props.width || width * (22.85 / 31.35);
        const defaults = {
          x: (page.width - width) / 2,
          y: (page.height - height) / 2,
          width: width,
          height: height
        };
        return this._addElement('barcode', _.extend(defaults, props), page);
      },

      addQrCode: function(props, page) {
        props = props || {};
        page = page || this.selected_page;
        if (!page) {
          throw new Error('Cannot add QR code; no page selected');
        }
        const size = props.size || page.width/4;
        const defaults = {
          x: (page.width - size) / 2,
          y: (page.height - size) / 2,
          size: size
        };
        return this._addElement('qrcode', _.extend(defaults, props), page);
      },

      addInlinePage: function(props, page) {
        props = props || {};
        page = page || this.selected_page;
        if (!page) {
          throw new Error('Cannot add inline page; no page selected');
        }
        const width = props.width || page.width/2;
        const height = props.height || page.height/2;
        const defaults = {
          edit: true,
          x: (page.width - width) / 2,
          y: (page.height - height) / 2,
          width: width,
          height: height,
          image_store: this.images
        };
        return this._addElement('ipage', _.extend(defaults, props), page);
      },

      addCalendar: function(props, page) {
        props = props || {};
        page = page || this.selected_page;
        if (!page) {
          throw new Error('Cannot add image; no page selected');
        }
        const defaults = {
          x: page.width * 1/8,
          y: page.height * 1/8,
          width: page.width * 3/4,
          height: page.height * 3/4
        };
        return this._addElement('calendar', _.extend(defaults, props), page);
      },

      addGroup: function(props, page) {
        props = props || {};
        page = page || this.selected_page;
        if (!page) {
          throw new Error('Cannot add image; no page selected');
        }
        return this._addElement('group', props, page);
      },

      addPages: function(position) {
        const project_store = this.project;
        this.undo_redo.withUndo(() => {
          project_store.addPages(position);
        }, {
          label: 'add pages'
        });
      },

      deleteSets: function(sets) {
        const project_store = this.project;
        this.undo_redo.withUndo(() => {
          project_store.deleteSets(sets, {cut_print_mode: this.cut_print_mode});
        }, {
          label: 'delete pages'
        });
      },

      duplicateSets: function(sets) {
        const project_store = this.project;
        this.undo_redo.withUndo(() => {
          project_store.duplicateSets(sets);
        }, {
          label: 'duplicate pages'
        });
      },

      moveSet: function(set, position) {
        const project_store = this.project;
        this.undo_redo.withUndo(() => {
          project_store.moveSet(set, position);
        }, {
          label: 'move pages'
        });
      },

      groupElement: function(element, group) {
        if (element.page !== group.page) {
          if (element.two_page_spread_clone && element.two_page_spread_clone.page === group.page) {
            element = element.two_page_spread_clone;
          } else {
            throw new Error(
              `Cannot group element; group is not on the same page as element!
              element: ${element.page.id}
              group: ${group.page.id}`
            );
          }
        }
        if (element.group) {
          throw new Error('Cannot group element; element already in a group!');
        }

        let elements;
        if (element.elements) {
          elements = element.elements.slice();
          element.elements.forEach(this.ungroupElement);
          element.destroy();
        } else {
          elements = [element];
        }

        elements.forEach(element => {
          const clone = element.clone();
          element.destroy();
          const w = clone.width;
          const h = clone.height;
          const r = clone.rotation;
          const origin = group.inGroupCoords(clone.center_point);
          clone.update({
            x: origin.x - w/2,
            y: origin.y - h/2,
            rotation: r - group.absolute_rotation,
            clone_id: null
          });
          group.addElement(clone);
        });
      },

      ungroupElement: function(element) {
        const group = element.group;
        if (!group) {
          throw new Error('Cannot ungroup element; element not in a group!');
        }
        const origin = group.inPageCoords(element.center_point);
        const w = element.width;
        const h = element.height;
        const r = element.rotation;
        const clone = element.clone();
        element.destroy();
        clone.update({
          x: origin.x - w/2,
          y: origin.y - h/2,
          rotation: r + group.absolute_rotation,
          clone_id: null
        });
        group.page.addElement(clone);
      },

      saveProject: function() {
        return this.project.save();
      },

      // When a local image is uploaded, we need to loop through all image elements in the project
      // and replace the old local id with the new database id.
      onImageUploaded: function(local_id, database_id) {
        this.project.images.forEach(function(image) {
          if (image.id === local_id) {
            image.id = database_id;
          }
        });
      },

      onPdfUploaded: function(local_id, database_id) {
        this.project.pdfs.forEach(function(pdf) {
          if (pdf.id === local_id) {
            pdf.id = database_id;
          }
        });
      },

      showNotification: function(message, opts) {
        if (typeof opts === 'undefined') {
          opts = {};
        } else if (typeof opts === 'string') {
          opts = {notification_type: opts};
        }
        this.notifications.notify(message, opts);
      },

      setOption: function(option, value) {
        this.project.setOption(option, value, {cut_print_mode: this.cut_print_mode});
      },

      setTemplateOption: function(option, value) {
        this.project.setTemplateOption(option, value, {cut_print_mode: this.cut_print_mode});
      },

      importCutPrintImage: function(image) {
        mobx.runInAction(() => {
          if (!this.images.get(image.id)) {
            this.images.register(image.id, image.data);
          }
          if (this.project.getImageElementsByImageId(image.id).length === 0) {
            this.project.addCutPrintImage(image.id);
          }
        });
      },

      importCutPrintImageFiles: function(files, callback) {
        const end_undo = this.undo_redo.beginWithUndo({label: 'add images'});
        let count = 1;
        this.galleries.project.importImages(files, image => {
          this.importCutPrintImage(image);
          if (count === files.length) {
            end_undo();
          } else {
            count++;
          }
          if (callback) {
            callback();
          }
        });
      },

      autofill: function(image_ids) {
        const end_undo = this.undo_redo.beginWithUndo({label: 'autofill', set_id: null});
        const image_count = image_ids.length;
        let remaining_image_ids = this.project.autofill(image_ids);

        if (remaining_image_ids.length === 0) {
          this.showNotification(Px.t('Filled {{count}} images.').replace('{{count}}', image_count), 'success');
        } else {
          const confirm_text = Px.t('Could not fill all images.') + '\n' + Px.t('Add more pages?');
          if (this.project.can_add_pages && confirm(confirm_text)) {
            remaining_image_ids = this._autofillWithNewPages(remaining_image_ids);
            if (remaining_image_ids.length === 0) {
              this.showNotification(Px.t('Filled {{count}} images.').replace('{{count}}', image_count), 'success');
            } else {
              this.showNotification(Px.t('Could not fill all images.'), 'warning');
            }
          } else {
            this.showNotification(Px.t('Could not fill all images.'), 'warning');
          }
        }

        end_undo();
        return remaining_image_ids;
      },

      _autofillWithNewPages: function(image_ids) {
        let unfilled_image_count = image_ids.length;
        while (unfilled_image_count > 0) {
          this.addPages();
          image_ids = this.project.autofill(image_ids);
          if (image_ids.length === unfilled_image_count) {
            // Adding new pages did not help fill any images, so give up.
            break;
          }
          unfilled_image_count = image_ids.length;
        }
        return image_ids;
      },

      fetchPrice: function(variables) {
        if (this._fetch_price_abort_controller) {
          this._fetch_price_abort_controller.abort();  // abort any in-flight request
        }
        this._fetch_price_abort_controller = new AbortController();

        if (variables) {
          const query = $j.param(variables);
          const url = `/v1/products/${this.project.product_id}/price_forecast.json?${query}`;
          const opts = {
            signal: this._fetch_price_abort_controller.signal
          };

          fetch(url, opts).then(r => r.json()).then(json => {
            this.price = json.price;
          }).catch(err => {
            if (err.name === 'AbortError') {
              console.log('product_forecast fetch aborted');
            } else {
              this.price = null;
              console.error(err);
            }
          });
        } else {
          this.price = null;
        }
      }

    };
  }

  isAutorotatedCutPrint(page) {
    if (!this.cut_print_mode) {
      return false;
    }
    return page.elements.some(element => element.tags.includes('px:autorotated'));
  }

  // -------
  // Private
  // -------

  _countedPageCount(count_double_pages_twice) {
    if (this.cut_print_mode) {
      return this.project.cut_print_sets_with_definitions.length;
    }
    let count = 0;
    this.project.sets_with_definitions.forEach(set_with_def => {
      if (set_with_def.definition.count) {
        const multiply = (count_double_pages_twice && set_with_def.set.double_page) ? 2 : 1;
        count += (set_with_def.set.pages.length * multiply);
      }
    });
    return count;
  }

  _uncountedPageCount(count_double_pages_twice) {
    if (this.cut_print_mode) {
      return 0;
    }
    let count = 0;
    this.project.sets_with_definitions.forEach(set_with_def => {
      if (!set_with_def.definition.count) {
        const multiply = (count_double_pages_twice && set_with_def.set.double_page) ? 2 : 1;
        count += (set_with_def.set.pages.length * multiply);
      }
    });
    return count;
  }

  _addElement(type, props, page) {
    if (typeof page === 'undefined') {
      page = this.selected_page;
    }
    if (!page) {
      throw new Error(`Cannot add ${type} element; no page selected`);
    }
    let element;
    if (type === 'calendar') {
      element = Px.Editor.CalendarElementModel.build(_.extend({page: page}, props));
    } else {
      element = Px.Editor.BaseElementModel.factory(type, _.extend({page: page}, props));
    }
    this.undo_redo.withUndo(function() {
      page.addElement(element);
    }, {
      label: `add ${type} element`,
      set_id: page.set.id
    });
    return element;
  }

};

Px.Editor.MainStore.BOOK_GALLERY_REFRESH_BACKOFF_DELAY = 30000;
Px.Editor.MainStore.BOOK_GALLERY_REFRESH_BACKOFF_DURATION = 2 * 60000;
Px.Editor.MainStore.BOOK_GALLERY_REFRESH_TIMEOUT_MS_RANGE = [1000, 8000];
