Px.Editor.QrCodeEditPanel = class QrCodeEditPanel extends Px.Editor.BaseComponent {

  template() {
    const r = this.renderChild;
    const qrcode = this.data.qrcode;

    return Px.template`
      <div class="px-image-edit-panel px-edit-panel">
        <div class="px-delete-element-section">
          <button class="px-small px-warning-color" data-onclick="deleteQrCode">
            ${Px.t('Delete')}
          </button>
        </div>

        <div class="px-edit-section">
          <div class="px-edit-controls">
            <div class="px-edit-control">
              <h2>${Px.t('Content')}</h2>
              <textarea type="text" data-onchange="setContent">${qrcode.content || ''}</textarea>
            </div>

            <div class="px-edit-control px-slider-control">
              <h2>${Px.t('Rotation')}</h2>
              ${r(Px.Components.SliderWithNumericInput, 'rotation-slider', this.rotationSliderProps)}
            </div>

            <div class="px-edit-control">
              <h2>${Px.t('Error Correction Level')}</h2>
              ${r(Px.Components.Dropdown, 'ec-level-select', this.errorCorrectionLevelProps)}
            </div>

            <div class="px-edit-control">
              <h2>${Px.t('Color')}</h2>
              ${r(Px.Components.ColorButton, 'color-button', this.colorButtonProps)}
            </div>
          </div>
        </div>
      </div>
    `;
  }

  constructor(data) {
    super(data);

    this.setContent = this.setContent.bind(this);
    this.selectColor = this.selectColor.bind(this);
    this.setErrorCorrectionLevel = this.setErrorCorrectionLevel.bind(this);
    this.setRotation = this.setRotation.bind(this);
    this.onBeforeRotationSliderDrag = this.onBeforeRotationSliderDrag.bind(this);
    this.onAfterRotationSliderDrag = this.onAfterRotationSliderDrag.bind(this);
  }

  get dataProperties() {
    return {
      qrcode: {required: true},
      store: {required: true}
    };
  }

  static get computedProperties() {
    return {
      colorButtonProps: function() {
        return {
          color: this.data.qrcode.color,
          title: Px.t('Choose color'),
          onClick: this.selectColor,
        };
      },
      rotationSliderProps: function() {
        return {
          min: -180,
          max: 180,
          step: 0.25,
          value: this.data.qrcode.rotation,
          onNewValue: this.setRotation,
          onBeforeDrag: this.onBeforeRotationSliderDrag,
          onAfterDrag: this.onAfterRotationSliderDrag
        };
      },
      errorCorrectionLevelProps: function() {
        return {
          value: this.data.qrcode.ec,
          options: [
            {value: 'l', name: Px.t('Low')},
            {value: 'm', name: Px.t('Medium')},
            {value: 'q', name: Px.t('Quartile')},
            {value: 'h', name: Px.t('High')}
          ],
          onNewValue: this.setErrorCorrectionLevel
        }
      }
    };
  }

  setErrorCorrectionLevel(ec) {
    this.withUndo('set error correction level', () => {
      this.data.qrcode.update({ec: ec});
    });
  }
  setRotation(rotation) {
    this.withUndo('rotate qr code', () => {
      this.data.qrcode.update({rotation: rotation});
    });
  }
  onBeforeRotationSliderDrag() {
    this.beginWithUndo('change qr code rotation');
  }
  onAfterRotationSliderDrag() {
    this.endWithUndo('change qr code rotation');
  }

  // --------------
  // Event handlers
  // --------------

  setContent(evt) {
    this.withUndo('change qr code content', () => {
      const content = evt.target.value;
      this.data.qrcode.update({content: content});
    });
  }

  selectColor(evt) {
    const qrcode = this.data.qrcode;
    const store = this.data.store;
    this.makeModal(Px.Editor.ColorPickerModal, {
      store: store,
      initial_color: qrcode.color,
      color_type_switch: store.color_picker_type_switch_enabled,
      onColorSelected: color => {
        this.withUndo('set qrcode color', () => {
          qrcode.update({color: color});
        });
      }
    });
  }

  deleteQrCode(evt) {
    this.withUndo('delete qr code element', () => {
      this.data.store.deleteElement(this.data.qrcode);
    });
  }

};
