Px.Editor.QrCodeElement = class QrCodeElement extends Px.Editor.BaseElementComponent {

  template() {
    const element = this.data.element;
    return Px.template`
      <g class="px-qrcode-element"
         data-element-id="${element.unique_id}"
         data-selected="${this.isSelected}"
         transform="${this.transformAttribute}"
         pointer-events="${this.pointerEventsAttribute}">
        ${Px.if(this.data.render_content, () => {
          return Px.template`
            <g pointer-events="${this.pointerEventsAttribute}">
              <g pointer-events="${this.pointerEventsAttribute}" opacity="${element.opacity}">
                <path d="${this.svgPathDefinition}"
                      fill="${Px.Util.colorForDisplay(element.color)}"
                      transform="scale(${this.scale})"
                />
              </g>
            </g>
          `;
        })}
        ${Px.if(this.data.render_controls, () => {
          return Px.template`
            ${this.selection_outline_template()}
            ${(this.data.preview_mode || !Px.config.advanced_edit_mode) ? '' : this.renderEditControls()}
          `;
        })}
      </g>
    `;
  }

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      scale: function() {
        const element = this.data.element;
        return element.size / element.qr_matrix.length;
      },
      // Converted to JS from code at:
      // https://github.com/whomwah/rqrcode/blob/e4a5bf2320afdad/lib/rqrcode/export/svg.rb#L48-L82
      svgPathDefinition: function() {
        const edge_matrix = structuredClone(this.data.element.qr_edge_matrix);
        let edge_count = edge_matrix.flat(2).filter(e => e).length;
        const path = [];

        while (edge_count > 0) {
          const edge_loop = [];
          const next_matrix_cell = edge_matrix.find(ey => ey.some(ex => ex)).find(ex => ex);
          let edge = next_matrix_cell[0];

          while (edge) {
            edge_loop.push(edge);
            const matrix_cell = edge_matrix[edge.start_y][edge.start_x];
            if (matrix_cell.length === 1) {
              edge_matrix[edge.start_y][edge.start_x] = null;
            } else {
              edge_matrix[edge.start_y][edge.start_x] = matrix_cell.filter(e => e !== edge);
            }
            edge_count -= 1;

            // Try to find an edge continuing the current edge.

            if (edge_matrix[edge.end_y][edge.end_x]) {
              edge = edge_matrix[edge.end_y][edge.end_x][0];
            } else {
              edge = null;
            }
          }

          const first_edge = edge_loop[0];
          let edge_loop_string = QrCodeElement.SVG_PATH_COMMANDS.move;
          edge_loop_string += `${first_edge.start_x} ${first_edge.start_y}`;

          const chunked = [];
          edge_loop.forEach(edge => {
            const last_chunk = chunked[chunked.length -1];
            if (last_chunk && last_chunk[0].direction === edge.direction) {
              last_chunk.push(edge);
            } else {
              chunked.push([edge]);
            }
          });

          chunked.pop();  // Don't care about the last one.
          chunked.forEach(edges => {
            edge_loop_string += `${QrCodeElement.SVG_PATH_COMMANDS[edges[0].direction]}${edges.length}`;
          });
          edge_loop_string += QrCodeElement.SVG_PATH_COMMANDS.close;

          path.push(edge_loop_string);

        }

        return path.join('');
      },
      qrCodeContentXXX: function() {
        const qrcode = this.data.element;
        const matrix = qrcode.qr_matrix;
        const scale = this.scale;
        const svg = [];
        const module_count = matrix.length;
        for (let row = 0; row < module_count; row++) {
		  for (let col = 0; col < module_count; col++) {
			if (matrix[row][col]) {
              const fill_color = Px.Util.colorForDisplay(qrcode.color);
              const x = col * scale;
              const y = row * scale;
              const width = scale;
              const height = scale;
              svg.push(`<rect fill="${fill_color}" x=${x} y="${y}" width="${width}" height="${height}" />`);
			}
		  }
		}
        return svg.join('\n');
      }
    });

  }

};

Px.Editor.QrCodeElement.SVG_PATH_COMMANDS = {
  move: 'M',
  up: 'v-',
  down: 'v',
  left: 'h-',
  right: 'h',
  close: 'z'
};
