// Contains methods shared between PageModel and GroupElementModel, which both contain child elements.
Px.Editor.ElementContainerMixin = Base => class extends Base {

  static get properties() {
    return Object.assign(super.properties, {
      _elements: {std: mobx.observable.array(), serialize: false}
    });
  }

  static get computedProperties() {
    return Object.assign(super.computedProperties || {}, {
      // Returns child elements in the proper order in which they should be laid onto the page.
      // Tries to match the ordering in page_preview.rb.
      _elements_in_z_order: function() {
        var graphic_elements = [];
        var score_elements = [];
        var barcode_elements = [];
        this._elements.forEach(function(element) {
          switch (element.type) {
          case 'image':
          case 'text':
          case 'pdf':
          case 'group':
          case 'grid':
          case 'calendar':
          case 'ipage':
          case 'qrcode':
            graphic_elements.push(element);
            break;
          case 'score':
            score_elements.push(element);
            break;
          case 'barcode':
            barcode_elements.push(element);
            break;
          }
        });
        // Markup z to illustrate original order of graphic elements in XML.
        var elements_by_z = {};
        graphic_elements.forEach(function(element) {
          var z = element.z;
          if (!elements_by_z.hasOwnProperty(z)) {
            elements_by_z[z] = [];
          }
          elements_by_z[z].push(element);
        });
        graphic_elements = [];
        var sorted_keys = _.sortBy(Object.keys(elements_by_z), function(key) {
          return parseInt(key, 10);
        });
        sorted_keys.forEach(function(z) {
          graphic_elements = graphic_elements.concat(elements_by_z[z]);
        });
        var elements_in_z_order = [].concat(
          graphic_elements,
          score_elements,
          barcode_elements
        );
        return elements_in_z_order;
      }
    });
  }

  // Just like this.elements.forEach, but descends into group elements and returns
  // a flattened list of all elements, including group children.
  forEachElement(fn) {
    this.elements.forEach(function(element) {
      fn(element);
      if (element.elements) {
        element.elements.forEach(function(child_element) {
          fn(child_element);
        });
      }
    });
  }

  getElementsByType(type) {
    var matching_elements = [];
    this.forEachElement(function(element) {
      if (element.type === type) {
        matching_elements.push(element);
      }
    });
    return matching_elements;
  }

  getElementsByTag(tag) {
    var matching_elements = [];
    this.forEachElement(function(element) {
      if (element.tags.includes(tag)) {
        matching_elements.push(element);
      }
    });
    return matching_elements;
  }

  xmlizeElements() {
    var output = [];
    this.elements.forEach(function(element) {
      if (element.is_in_viewport) {
        output.push(element.xml);
      }
    });
    return output.join('\n');
  }

  moveElementToTopOfLayer(element, layer) {
    mobx.runInAction(() => {
      this._elements.remove(element);
      // Now find index of the last element inside the requested layer.
      // Note: using `.reverse().findIndex()` because `.findLastIndex()` is not yet well supported.
      const last_layer_idx = (this._elements.length - 1) - this._elements.reverse().findIndex(e => {
        // Barcodes and scores are not graphic elements, so ignore those.
        return e.z === layer && !(e.type === 'barcode' || e.type === 'score');
      });
      // Then move the element after the last element in the layer.
      this._elements.splice(last_layer_idx + 1, 0, element);
    });
  }

  moveElementToBottomOfLayer(element, layer) {
    mobx.runInAction(() => {
      this._elements.remove(element);
      // Now find index of the first element inside the requested layer.
      const first_layer_idx = this._elements.findIndex(e => {
        // Barcodes and scores are not graphic elements, so ignore those.
        return e.z === layer && !(e.type === 'barcode' || e.type === 'score');
      });
      // Then move the element after the last element in the layer.
      this._elements.splice(first_layer_idx, 0, element);
    });
  }

  // ---------------
  // Getters/setters
  // ---------------

  get elements() {
    return this._elements_in_z_order;
  }

  set elements(elements) {
    mobx.runInAction(() => {
      elements.forEach(element => {
        this.addElement(element);
      });
    });
  }

};
