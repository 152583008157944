Px.Editor.UserGalleryStore = class UserGalleryStore extends Px.BaseStore {

  static get properties() {
    return {
      galleries: {std: mobx.observable.map()},
      albums_loading: {std: false}
    };
  }

  get actions() {
    return {
      init: function() {
        if (this.galleries.size === 0) {
          this.loadGalleries();
        }
      },

      loadGalleries: function() {
        this.galleries.clear();
        this.albums_loading = true;
        fetch('/v1/galleries/_mine.json').then(r => r.json()).then(json => {
          mobx.runInAction(() => {
            this.galleries.clear();
            json.forEach(gallery => {
              this.galleries.set(String(gallery.id), this.buildGalleryItem(gallery));
            });
            this.albums_loading = false;
          });
        });
      },

      loadGalleryImages: function(gallery_id) {
        const gallery = this.getGallery(gallery_id);
        gallery.images.clear();
        gallery.loading = true;
        fetch(`/v1/galleries/${gallery_id}/images.json`).then(r => r.json()).then(json => {
          mobx.runInAction(() => {
            const items = [];
            json.forEach(image => {
              items.push(this.buildImageItem(image));
            });
            gallery.images.replace(items);
            gallery.loading = false;
          });
        });
      },

      createGallery: function(gallery_name) {
        return fetch(`/v1/users/${Px.config.user_id}/galleries`, {
          method: 'POST',
          body: new URLSearchParams({name: gallery_name})
        });
      },

      removeImage: function(gallery, image) {
        const idx = gallery.images.indexOf(image);
        gallery.images.remove(image);
        return fetch(`/v1/images/${image.data.id}.json`, {
          method: 'PUT',
          body: new URLSearchParams({'image[gallery_id]': ''})
        }).catch(err => {
          gallery.images.splice(idx, 0, image);
        });
      }
    };
  }

  getGallery(gallery_id) {
    // Make sure galery_id is always a string.
    return this.galleries.get(String(gallery_id));
  }

  galleryImages(gallery_id) {
    return this.getGallery(gallery_id).images;
  }

  isGalleryLoaded(gallery_id) {
    if (gallery_id === null) {
      return !this.albums_loading;
    } else {
      const gallery = this.getGallery(gallery_id);
      return gallery && !gallery.loading;
    }
  }

  buildGalleryItem(item) {
    return {
      type: 'gallery',
      id: item.id,
      loading: true,
      caption: item.name,
      images: mobx.observable.array()
    };
  }

  buildImageItem(item) {
    return {
      type: 'image',
      id: `db:${item.id}`,
      thumb_url: item.preview,
      caption: item.filename,
      data: item
    };
  }

};
