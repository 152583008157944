Px.Editor.UserGalleryPanel = class UserGalleryPanel extends Px.Editor.BaseGalleryPanel {

  gallery_action_buttons_template() {
    if (this.state.selected_gallery_id === null) {
      return Px.template`
        <button data-onclick="addGalleryModal">
          ${Px.raw(UserGalleryPanel.icons.new_folder)}
          ${Px.t('New gallery')}
        </button>
      `;
    } else {
      return super.gallery_action_buttons_template();
    }
  }

  get css_class() {
    return 'px-user-gallery-panel';
  }

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      galleryStore: function() {
        return this.data.store.galleries.user;
      },
      items: function() {
        if (this.state.selected_gallery_id) {
          return this.galleryStore.galleryImages(this.state.selected_gallery_id);
        } else {
          return Array.from(this.galleryStore.galleries.values());
        }
      },
      canRemoveImages: function() {
        return true;
      },
      isLoading: function() {
        return !this.galleryStore.isGalleryLoaded(this.state.selected_gallery_id);
      },
      canLoadMoreItems: function() {
        return false;
      },
      canGoUpLevel: function() {
        return this.state.selected_gallery_id !== null;
      },
      upLevelLabel: function() {
        const gallery = this.galleryStore.galleries.get(this.state.selected_gallery_id);
        return gallery.caption;
      },
      showUploadButton: function() {
        return Boolean(this.state.selected_gallery_id);
      },
      showImageSizeButtons: function() {
        return true;
      },
    });
  }

  // --------------
  // Event handlers
  // --------------

  onGalleryClick(evt) {
    super.onGalleryClick(evt);
    this.galleryStore.loadGalleryImages(this.state.selected_gallery_id);
  }

  upLevel(evt) {
    this.state.selected_gallery_id = null;
  }

  addGalleryModal(evt) {
    this.makeModal(Px.Editor.AddGalleryModal, {
      galleryStore: this.galleryStore
    });
  }

  onFilesSelected(evt) {
    const gallery_store = this.galleryStore;
    const files = Px.LocalFiles.Validation.filterAndValidateFiles(evt.target.files);
    if (files) {
      const gallery_id = this.state.selected_gallery_id;
      gallery_store.galleries.get(gallery_id).loading = true;
      const upload_url = `/upload/image?gallery_id=${gallery_id}`;
      const uploader = Px.LocalFiles.Uploader.make(upload_url);
      files.forEach(file => {
        const local_file = Px.LocalFiles.LocalFileModel.make(file);
        mobx.when(() => local_file.upload_success, () => gallery_store.loadGalleryImages(gallery_id));
        uploader.uploadFile(local_file);
      });
    }
  }

  async removeImage(image_id) {
    const gallery = this.galleryStore.galleries.get(String(this.state.selected_gallery_id));
    const image = gallery.images.find(image => image.id === image_id)
    try {
      await this.galleryStore.removeImage(gallery, image);
    } catch (err) {
      alert('Failed to remove image from gallery.');
      throw err;
    }
  }

};

Px.Editor.AddGalleryModal = class AddGalleryModal extends Px.Components.BaseModal {

  get title() {
    return Px.t('New Gallery');
  }

  get content() {
    return Px.template`
      <input class="px-gallery-name-input" type="text" placeholder="${Px.t('Gallery name')}" required />
    `;
  }

  get footer() {
    return Px.template`
      <button class="px-cancel" data-onclick="destroy">
        ${Px.t('Cancel')}
      </button>
      <button class="px-ok" data-onclick="createGallery">
        ${Px.t('Create Gallery')}
      </button>
    `;
  }

  get css_class() {
    return `${super.css_class} px-add-gallery-modal px-confirmation-modal`;
  }

  constructor(props) {
    super(props);
    this.on('mount', () => {
      this.dom_node.querySelector('input').focus();
    });
  }

  get dataProperties() {
    return {
      galleryStore: {required: true}
    }
  }

  // --------------
  // Event handlers
  // --------------

  createGallery(evt) {
    const input = this.dom_node.querySelector('.px-gallery-name-input');
    if (input.reportValidity()) {
      const name = input.value.trim();
      if (name) {
        const promise = this.data.galleryStore.createGallery(name);
        promise.then(() => {
          this.data.galleryStore.loadGalleries();
        });
        promise.finally(() => {
          this.destroy();
        });
      }
    }
  }

};
