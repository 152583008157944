Px.Editor.ProjectGalleryPanel = class ProjectGalleryPanel extends Px.Editor.BaseGalleryPanel {

  no_items_template() {
    return Px.template`
      <div class="px-no-items-content px-action-description-area ${this.state.drop_active ? 'px-drop-active' : ''}"
           data-ondrop="onItemsDropped"
           data-ondragenter="onDragEnter"
           data-ondragover="onDragOver"
           data-ondragleave="onDragLeave">
        <p>
          <span class="px-upload-icon">${Px.raw(Px.Editor.ProjectGalleryPanel.icons.upload)}</span>
          ${Px.t('Drag and drop images or upload from your computer.')}
        </p>
        ${this.upload_button_template({upload_method_modal: Px.config.qr_uploader})}
      </div>
    `;
  }

  gallery_items_template() {
    return Px.template`
      <div class="px-gallery-items"
           data-item-size="${this.data.store.ui.sidebar_item_size}"
           data-ondrop="onItemsDropped"
           data-ondragenter="onDragEnter"
           data-ondragover="onDragOver"
           data-ondragleave="onDragLeave">
        ${this.itemsWithUsage.map(item_with_usage => this.item_template(item_with_usage.item, item_with_usage.usage))}
      </div>
    `;
  }

  get css_class() {
    return 'px-project-gallery-panel';
  }

  static get properties() {
    return Object.assign(super.properties, {
      drop_active: {type: 'bool', std: false}
    });
  }

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      galleryStore: function() {
        return this.data.store.galleries.project;
      },
      items: function() {
        if (this.data.store.cut_print_mode) {
          // We never show images in the upload panel in cut print mode,
          // they are added straight to the project on drop/select.
          return [];
        } else {
          return this.galleryStore.images;
        }
      },
      canRemoveImages: function() {
        return true;
      },
      showAutofillButton: function() {
        return Px.config.autofill_button && !this.data.store.cut_print_mode && this.items.length > 0;
      },
      autofillButtonDisabled: function() {
        return this.unusedImages.length === 0 || this.galleryStore.import_in_progress;
      },
      showUploadButton: function() {
        return this.images.length > 0;
      },
      uploadButtonLabel: function() {
        if (this.galleryStore.import_in_progress) {
          return Px.t('Importing {{count}} images...').replace('{{count}}', this.galleryStore.pending_import_count);
        } else {
          return Px.t('Upload images');
        }
      },
      uploadButtonDisabled: function() {
        return this.galleryStore.import_in_progress;
      }
    });
  }

  // --------------
  // Event handlers
  // --------------

  onFilesSelected(evt) {
    const files = Px.LocalFiles.Validation.filterAndValidateFiles(evt.target.files);
    if (files) {
      if (this.data.store.cut_print_mode) {
        this.data.store.importCutPrintImageFiles(files);
      } else {
        this.importAndSelectImages(files);
      }
    }
  }

  onItemsDropped(evt) {
    evt.preventDefault();
    let files = Px.Util.getDataTransferFiles(evt);
    files = Px.LocalFiles.Validation.filterAndValidateFiles(files);
    if (files) {
      if (this.data.store.cut_print_mode) {
        this.data.store.importCutPrintImageFiles(files);
      } else {
        this.importAndSelectImages(files);
      }
    }
    this.state.drop_active = false;
  }

  onDragEnter(evt) {
    evt.preventDefault();
  }

  onDragOver(evt) {
    evt.preventDefault();
    this.state.drop_active = true;
  }

  onDragLeave(evt) {
    this.state.drop_active = false;
  }

  // -------
  // Private
  // -------

  importAndSelectImages(files) {
    let scrolled = false;
    this.galleryStore.importImages(files, image => {
      if (image) {
        if (!scrolled) {
          setTimeout(() => {
            const element = document.querySelector(`.px-image[data-image-id="${image.id}"]`);
            element.scrollIntoView({behavior: 'smooth', block: 'start'});
          }, 100);
        }
        scrolled = true;
      }
    });
  }

  async removeImage(image_id) {
    const image_store = this.data.store.images;
    let image = this.galleryStore.images.find(image => image.id === image_id);
    // If this is a local image that has been successfully uploaded in this session,
    // the id in the gallery store will still be using the local ID. We must make sure
    // to search by (resolved) local ID as well.
    if (!image) {
      image = this.galleryStore.images.find(image => {
        const store_image = image_store.get(image.id);
        return store_image && store_image.id === image_id;
      });
    }
    try {
      await this.galleryStore.removeImage(image);
    } catch (err) {
      alert('Failed to remove image from project gallery.');
      throw err;
    }
  }

};

Px.Editor.ProjectGalleryPanel.icons = {
  upload: '<svg width="42px" height="42px" viewBox="0 0 42 42"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" transform="translate(-1287.000000, -344.000000)" opacity="0.5"><g transform="translate(1176.000000, 0.000000)" fill="currentColor" fill-rule="nonzero"><g transform="translate(16.000000, 135.000000)"><g transform="translate(95.000000, 209.000000)"><path d="M40,33.040461 C40,32.4881762 40.4477153,32.040461 41,32.040461 C41.5522847,32.040461 42,32.4881762 42,33.040461 L42,36.3569356 C42,39.5849976 39.3027541,42 35.8148148,42 L6.36,42 C2.87055287,42 0,39.429078 0,36.200398 L0,33 C0,32.4477153 0.44771525,32 1,32 C1.55228475,32 2,32.4477153 2,33 L2,36.200398 C2,38.2723607 3.92898884,40 6.36,40 L35.8148148,40 C38.2444308,40 40,38.4281359 40,36.3569356 L40,33.040461 Z"></path><path d="M20.3195736,0.267177772 C20.498051,0.101385069 20.7371856,0 21,0 C21.2628144,0 21.501949,0.101385069 21.6804264,0.267177772 L34.6782801,12.2651966 C35.0841008,12.6398003 35.1094072,13.2724594 34.7348034,13.6782801 C34.3601997,14.0841008 33.7275406,14.1094072 33.3217199,13.7348034 L22,3.28398508 L22,33 C22,33.5522847 21.5522847,34 21,34 C20.4477153,34 20,33.5522847 20,33 L20,3.28398508 L8.6782801,13.7348034 C8.27245937,14.1094072 7.63980031,14.0841008 7.26519656,13.6782801 C6.8905928,13.2724594 6.91589916,12.6398003 7.3217199,12.2651966 L20.3195736,0.267177772 Z"></path></g></g></g></g></svg>'
};
