Px.Editor.BaseUploadGalleryStore = class BaseUploadGalleryStore extends Px.BaseStore {

  constructor(image_store) {
    super();
    this.image_store = image_store;
  };

  static get properties() {
    return {
      loaded: {std: false},
      gallery_loading: {std: false},
      images: {std: mobx.observable.array()},
      pending_import_count: {std: 0}
    };
  }

  static get computedProperties() {
    return {
      gallery_url: function() {
        throw Error('Implement in subclass');
      },
      import_in_progress: function() {
        return this.pending_import_count > 0;
      },
      local_images: function() {
        return this.images.filter(image => this.isLocalImage(image));
      }
    };
  }

  get actions() {
    return {
      load: function() {
        return this.loadGalleryImages();
      },

      loadGalleryImages: function() {
        if (!this.gallery_url) {
          return;
        }
        if (this.gallery_loading) {
          return this._fetch_request;
        }

        this.gallery_loading = true;

        this._fetch_request = fetch(this.gallery_url).then(r => r.json()).then(json => {
          const current_images = this.images.toJS();
          mobx.runInAction(() => {
            json.forEach(image => {
              const img_id = `db:${image.id}`;
              const image_exists = current_images.some(local_image => {
                const store_image = this.image_store.get(local_image.id);
                const resolved_image_id = (store_image && store_image.id) || local_image.id;
                return resolved_image_id === img_id;
              });
              if (!image_exists) {
                if (!this.image_store.get(img_id)) {
                  this.image_store.register(img_id, image);
                }
                this._addDbImage(image);
              }
            });
          });
        }).then(() => {
          this.loaded = true;
        }).catch(err => {
          console.error(err);
        }).finally(() => {
          this.gallery_loading = false;
        });

        return this._fetch_request;
      },

      importImages: function(files, callback) {
        files = _.clone(files);
        // Processes next file. When done processing one file,
        // recursively calls itself again.
        this.pending_import_count += files.length;
        const imported_items = [];
        const processNext = () => {
          const file = files.shift();
          if (file) {
            this._importImage(file, item => {
              if (callback) {
                callback(item);
              }
              this.pending_import_count--;
              imported_items.push(item);
              processNext();
            });
          } else {
            imported_items.forEach(item => this._generateThumbnail(item));
          }
        };
        processNext();
      },

      importImage: function(file, callback) {
        this.pending_import_count++;
        this._importImage(file, image => {
          this.pending_import_count--;
          callback(image);
          this._generateThumbnail(image);
        });
      },

      _importImage: function(file, callback) {
        if ((file.type && !(file.type.match('image/.*') || file.type === 'application/pdf')) ||
            (!file.type && !file.name.match(/\.heic$/i))) {
          throw new Error(`Not an image file: ${file.name}`);
        }

        Px.LocalFiles.reader.readImage(file, {}, (blob, width, height) => {
          // Only add the image to the gallery if image was successfully read.
          if (blob) {
            const image = this._addLocalImage({
              file: file,
              thumb_url: this.placeholderThumbnail(file, width, height),
              width: width,
              height: height
            });
            callback(image);
          } else {
            callback(null);
          }
        });
      },

      _addDbImage: function(props, callback) {
        const item = this.buildDbImageItem(props);
        this.images.push(item);
        return item;
      },

      _addLocalImage: function(props) {
        const item = this.buildLocalImageItem(props);
        this.images.push(item);
        return item;
      },

      _generateThumbnail: function(item) {
        const opts = {width: BaseUploadGalleryStore.THUMBNAIL_WIDTH, priority: 9};
        Px.LocalFiles.reader.readImage(item.data.file, opts, blob => {
          if (blob) {
            const url = URL.createObjectURL(blob);
            mobx.runInAction(() => {
              item.thumb_url = url;
              item.data.thumb_url = url;
            });
          }
        });
      }
    };
  }

  // -------
  // Private
  // -------

  buildLocalImageItem(props) {
    var guid = Px.Util.guid();
    return mobx.observable.object({
      type: 'image',
      id: `local:${guid}`,
      thumb_url: props.thumb_url,
      caption: props.file.name,
      data: props
    });
  }

  buildDbImageItem(item) {
    return {
      type: 'image',
      id: `db:${item.id}`,
      thumb_url: item.preview,
      caption: item.filename,
      data: item
    };
  }

  isLocalImage(image) {
    const store_image = this.image_store.get(image.id);
    return image.id.startsWith('local:') && !(store_image && store_image.type === 'db');
  }

  placeholderThumbnail(file, width, height) {
    const aspect_ratio = height / width;
    const thumbnail_width = BaseUploadGalleryStore.THUMBNAIL_WIDTH;
    const thumbnail_height = Math.round(aspect_ratio * thumbnail_width);
    const viewbox_width = 80;
    const viewbox_height = aspect_ratio * viewbox_width;
    const offset_x = -21;
    const offset_y = offset_x + (viewbox_width - viewbox_height)/2;
    const svg = `
      <svg width="${thumbnail_width}"
           height="${thumbnail_height}"
           viewBox="${offset_x} ${offset_y} ${viewbox_width} ${viewbox_height}"
           style="background-color:#eee"
           xmlns="http://www.w3.org/2000/svg">
        <g stroke-width="0.5" fill="none" stroke="#8492A6" fill-rule="evenodd" transform="translate(8, 3)">
          <polyline stroke-linejoin="round" points="20 19 15.7226667 9 11.9875556 16.4710477 8.25125926 12.395637 4 19" />
          <polygon points="24 24 0 24 0.024 0.023976024 24 0" />
          <path d="M0,19 L24,19" />
          <path d="M8,7 C8,8.105 7.1045,9 6,9 C4.895,9 4,8.105 4,7 C4,5.896 4.895,5 6,5 C7.1045,5 8,5.896 8,7 L8,7 Z"
                stroke-linejoin="round"
          />
        </g>
        <text x="20"
              y="33.5"
              text-anchor="middle"
              stroke-width="0"
              fill="#8492A6"
              font-size="4"
              font-family="sans-serif">
          ${Px.Util.truncateFilename(file.name, 30)}
        </text>
      </svg>
    `;

    return `data:image/svg+xml,${svg.trim().replace(/\n/g, ' ').replace(/#/g, '%23')}`;
  }

};

Px.Editor.BaseUploadGalleryStore.THUMBNAIL_WIDTH = 2 * 237;
